<template>
   <div class="smart-wrap" style="border: dashed 1px #ccc;">
      <div class="container-in" id="envelop">
         <div class="container-in" style="max-width: 900px; margin: 100px auto 0; text-align: left;">
            <h4 v-if="record" style="font-size: 28px; font-weight: 600; margin: 0 0 10px; ">
               {{record.subject.value}} 
            </h4>
            <h5 v-if="record" style="font-size: 20px;  margin: 0 0 50px 0; font-style: italic;">
               {{record.subText1.value}} 
               <br>
               {{record.subText2.value}} 
            </h5>
            <div class="redes" style="display: flex; margin: 0 0 10px 0;">
               <a onclick="share('facebook')" class="bubble" style=" width: 25px; height: 25px; margin: 0 25px 0 0; opacity: 0.8;" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="#6e6e73" d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"/></svg>
               </a>
               <a onclick="share('twitter')" class="bubble" style=" width: 25px; height: 25px; margin: 0 25px 0 0; opacity: 0.8;" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#6e6e73" d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"/></svg>
               </a>
               <a onclick="share('linkedin')" class="bubble" style=" width: 25px; height: 25px; margin: 0 25px 0 0; opacity: 0.8;" >
                  <svg  xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><circle cx="4.983" cy="5.009" r="2.188" fill="#6e6e73"/><path fill="#6e6e73" d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118c1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783c-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"/></svg>
               </a>
               <a onclick="share('email')" class="bubble" style=" width: 25px; height: 25px; margin: 0 25px 0 0; opacity: 0.8;" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#6e6e73" d="m20 8l-8 5l-8-5V6l8 5l8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"/></svg>
               </a>
               <a onclick="share('link')" class="bubble" style=" width: 25px; height: 25px; margin: 0 25px 0 0; opacity: 0.8;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#6e6e73" d="M10.59 13.41c.41.39.41 1.03 0 1.42c-.39.39-1.03.39-1.42 0a5.003 5.003 0 0 1 0-7.07l3.54-3.54a5.003 5.003 0 0 1 7.07 0a5.003 5.003 0 0 1 0 7.07l-1.49 1.49c.01-.82-.12-1.64-.4-2.42l.47-.48a2.982 2.982 0 0 0 0-4.24a2.982 2.982 0 0 0-4.24 0l-3.53 3.53a2.982 2.982 0 0 0 0 4.24m2.82-4.24c.39-.39 1.03-.39 1.42 0a5.003 5.003 0 0 1 0 7.07l-3.54 3.54a5.003 5.003 0 0 1-7.07 0a5.003 5.003 0 0 1 0-7.07l1.49-1.49c-.01.82.12 1.64.4 2.43l-.47.47a2.982 2.982 0 0 0 0 4.24a2.982 2.982 0 0 0 4.24 0l3.53-3.53a2.982 2.982 0 0 0 0-4.24a.973.973 0 0 1 0-1.42Z"/></svg>
               </a>
            </div>
            <div id="text" style="font-size: 16px; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 20px 0;">
            </div>
         </div>
      </div>
   </div>
</template>
<script>
//AWS
import { smd_getRecordObj, smd_generateWebSite } from '../../graphql/queries'
import { } from '../../graphql/mutations'
import { loading, popUp } from '../../store/tools.js'

//Tools
import Vuex from 'vuex'
import { API, Storage, Auth } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid';
//Components
import PaperLayout from '../papers/PaperLayout.vue'
import PaperDisplayer from '../papers/PaperDisplayer.vue'
export default {
   name: 'paper-render-sml',
   components: {
      PaperLayout,
      PaperDisplayer,
   },
   data() {
      return {
         record: undefined
      }
   },
   props: {
      data: {
         type: Object,
         default: {
            SK: '',
            PK: '',
         }
      }
   },
   created() {
      this.getData()
   },
   methods: {
      ...Vuex.mapMutations([]),
      ...Vuex.mapActions([]),
      //Get Web Page
      async getData(){
         let pullData = ''
         let pushData = {
            PK: this.data.PK,
            SK: this.data.SK,
         }
         try {
            pullData = await API.graphql({
               query: smd_getRecordObj,
               variables:pushData
            })
            pullData = pullData.data.smd_getRecordObj
            if (pullData.smd_response.statusCode != 200) {
               popUp(pullData.smd_response, 'response', 'err')
               loading('hidde', 'getItem')
               return
            }
            else if (pullData.smd_response.popAlert == true) {
               popUp(pullData.smd_response, 'response', 'scc')
            }
            pullData = JSON.parse(pullData.data)
            this.record = pullData
            console.log(this.record);
            
            document.getElementById('text').innerHTML =  pullData.body.value
            setTimeout(() => {
               this.upSML()
            }, 500);
            return
            setTimeout(() => {
               document.getElementById('text').innerHTML = this.art.text
               this.html.body = document.getElementById('envelop').innerHTML
               this.html.title = '<title>Blog | '+ this.art.title+' </title> \n <meta property="og:title" content="Biz Plan Easy | '+this.art.title+'" />'
               this.html.description = '<meta property="og:description" content="'+ this.art.headline+'" />'
               this.html.img = '<meta property="og:image" content="'+this.art.img+'" />'
               
               //document.getElementById('code').innerText = document.getElementById('envelop').innerHTML
               this.update(data)
            }, 500);
         } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
         }
      },
      async upSML() {
         loading('show', 'webPreview')
         let pushData = {};
         let pullData = ''
         try {
            pushData ={
               itemPK: this.data.PK,
               itemSK: this.data.SK,
               destination: this.record.path.value,
               template: this.record.template.value,
               HTML0:document.getElementById('envelop').innerHTML,
               HTML1:document.getElementById('envelop').innerHTML,
               HTML2:document.getElementById('envelop').innerHTML,
               HTML3:document.getElementById('envelop').innerHTML,
            }
            console.log('pushData HTML of SML:'+ this.record.path.value, pushData);
            pullData = await API.graphql({
               query: smd_generateWebSite,
               variables: pushData
            });
            pullData = pullData.data.smd_generateWebSite
            console.log('Response of Upload HTML of SML:'+ this.record.path.value, pullData);
            //this.$emit('show', 'design')

            //Render and catch of Web
         } catch (error) {
            console.log(error);
            popUp(error, 'response', 'err')
         }
         loading('hide', 'webPreview')
      },
   },
   computed: {
      ...Vuex.mapState(['organization'])
   },
}
</script>