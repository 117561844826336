<template>
   <PaperRenderDetail :data="{ PK: 'ORG#KARMAWINK', SK:'WEB#ad258133-5743-4a61-97db-e3c4f9d0e8ff'}"/>
   <div class="container-in">
   </div>
</template>
<script>
//Tools
import Vuex from "vuex";
import { Auth, API } from "aws-amplify";
import "amazon-connect-streams";

//Components
import PaperRenderDetail from "../components/papers/PaperRenderDetail.vue";
import PaperDisplayer from "../components/papers/PaperDisplayer.vue";

export default {
   name: "dev",
   components:{
      PaperDisplayer,
      PaperRenderDetail,
   },

   data() {
      return {
         data: '1234',
      }
   }, 
   methods: {
   },
   computed: {
      ...Vuex.mapState(["organizationID", "pageForm", "pageTable", "pageName", "pageQuery", "entity", "pageMeta", "pageModule", "permissions", "", 'recordorganizationData', 'publicOrganization', 'cartData', 'organizationData','preloadLists','recordLists','products']),

   },
}
</script>